// config for staging01 e2e tests
const baseUrl = `https://app.staging01.meliopayments.com`;

export default {
  server: {
    baseUrl,
  },
  web: {
    baseUrl,
  },
  platformApp: {
    baseUrl: `https://partnerships.staging01.melioservices.com/melio`,
  },
  analytics: {
    shouldTrackEvents: true,
    shouldPrintEvents: true,
    shouldIdentify: true,
  },
  services: {
    braze: {
      enable: true,
      apiKey: '2241af2b-0711-4852-bd8d-cb013a85175c',
      options: {
        baseUrl: 'sdk.iad-05.braze.com',
        enableLogging: true,
        localization: 'en',
      },
    },
    intuit: {
      clientId: 'Q0gqJI4IaP8fEpNFaZeDOuw0LBiN7dPuUnbm5AZVYiNtb39Prz',
    },
    segment: {
      key: 'ktaEnCSilTDj4NuZ1Ny6rINi0rnZsAK4',
      shouldLoad: true,
    },
    intercom: {
      app_id: 'a44mis4s',
      isShow: true,
    },
    featureFlagProvider: {
      clientId: '608a6c9ebedae00c6063d332',
    },
  },
  quickbooks: {
    baseUrl: 'https://app.sandbox.qbo.intuit.com',
  },
  qbo: {
    web: {
      baseUrl: `https://staging01-intuit-app.meliopayments.com`,
    },
  },
  meliome: {
    baseUrl: `${baseUrl}/meliome/pay/`,
  },
  debounceDelay: 10,
  featureFlags: {},
  spendManagement: {
    amexExtensionId: 'hnialanckkpjiichppfnclgkbohlioln',
  },
};
